import React, { useState, FunctionComponent, ReactElement } from 'react'
import { useMediaQuery } from "@react-hook/media-query";
import Layout from '../components/layout'

const CreditsPage: FunctionComponent = (): ReactElement => {
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  
  return ( 
      <Layout
        postTitle="Flux"
        postImage={undefined}
        postDescription="The Varsity's 2021 magazine"
        isHome={false}
        displayArrows={false}
        className="bg-letterToEditor"
      >
        <div className="w-full h-full pt-20 pb-16 px-4 md:px-16">
          <h1 className="text-5xl mb-4">Credits</h1>
          
          <p><span className="font-bold">Magazine Editor:</span> Stephanie Bai</p>

          <p><span className="font-bold">Creative Director:</span> Nathalie Whitten</p>

          <p><span className="font-bold">Editor-in-Chief:</span> Ibnul Chowdhury</p>

          <p><span className="font-bold">Managing Online Editor:</span> Silas Le Blanc</p>

          <p><span className="font-bold">Senior Copy Editor:</span> Megan Brearley</p>

          <p><span className="font-bold">Deputy Senior Copy Editor:</span> Maya Morriswala</p>

          <p><span className="font-bold">Design Editors:</span> Aditi Putcha and William Xiao</p>

          <p><span className="font-bold">Photo Editor:</span> Samantha Yao</p>

          <p><span className="font-bold">Illustration Editor:</span> Fiona Tung</p>

          <p><span className="font-bold">Video Editor:</span> Dina Dong</p>

          <p><span className="font-bold">Front End Web Developer:</span> Munachi Ernest-Eze</p>

          <p><span className="font-bold">Back End Web Developer:</span> Andrew Hong</p>

          <p><span className="font-bold">Magazine Web Developer:</span> Zack Radisic</p>

          <p><span className="font-bold">Magazine Assistants:</span> Joshua Chong, Jadine Ngan, Kashi Syal</p>

          <p><span className="font-bold">Associate Senior Copy Editors:</span> Talha Anwar Chaudhry, Sarah Kronenfeld, Nawa Tahir</p>

          <p><span className="font-bold">Associate Photo Editors:</span> Caroline Bellamy and Nathan Ching</p>

          <p><span className="font-bold">Associate Illustration Editor:</span> Rebeca Moya and Celene Czarnota</p>

          <p><span className="font-bold">Business Manager:</span> Joy Fan</p>

          <p><span className="font-bold">Writers:</span> Lauren Alexander, Sherene Almjawer, Caleb Chan, Zhen Chew, Nathan Ching, Joshua Chong, Angad Deol, Nancy Dutra, Muzna Erum, Kate Haberl, Angel Hsieh, Sky Kapoor, Anastasia Kasirye, Janus Kwong, Gladys Lou, Vurjeet Madan, Jesse McDougall, Emory Claire Mitchell, Jadine Ngan, Sherry Ning, Isabella Tan, Shankeri Vijayakumar, Wambui Waiganjo, Valerie Yao</p>

          <p><span className="font-bold">Photographers:</span> Pirakasini (Kashi) Chandrasegar, Samantha Yao, Jadine Ngan, respective authors</p>

          <p><span className="font-bold">Illustrators:</span> Ananya Ananth, Jessica Lam, Isabella Tan, Fiona Tung</p>

          <p><span className="font-bold">Copy Editors:</span> Amena Ahmed, Rachel Banh, Toryanse Blanchard, Siu Ching Chan, Esther Choi, Melissa Cusack Striepe, Melissa D’amico, Anita Ding, Dana Hamze, Drishti Jalan, Karen Kan, Easha Khan, Christina Lam, Morgan Lee, Mona Liu, Violet Mackintosh, Jade Goh McMillen, Shernise Mohammed-Ali, Oeishi Mukherjee, Valentina Palacio, Safiya Patel, Jareeat Purnava, Savannah Ribeiro, Akshita Sangha, Dellannia Segreti, Rebecca Skoll, Teresa Wang, Malka Younas, Cherry Zhang</p>

          <p><span className="font-bold">Website Design:</span> Munachi Ernest-Eze, Andrew Hong, and Zack Radisic</p>

          <p><span className="font-bold">Cover Illustration:</span> Nathalie Whitten</p>

          <p><span className="font-bold">Section Cover Illustrations:</span> Nathalie Whitten</p>
        </div>
    </Layout>);
}

export default CreditsPage
